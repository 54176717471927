$slope: skewY(9.4deg);

html {
  background: #373B47;
  height: 100vh
}
.fullscreen {
  height: 100vh !important;
  width: 100vw !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.home {
  text-align: center;
  background: #373B47;
  margin: auto;
  overflow: hidden;
  max-width: 100vw;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  // color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.buttons-container {
  width: 50em;
  margin: auto;
  background: #033eef;
}

$blogs-left: auto;
$blogs-top: auto;

.blogs-button-back {
  box-sizing: border-box;

  position: fixed;
  width: 204.52px;
  height: 86px;
  left: 377.24px;
  top: 320px;

  background: #76B7CC;
  border: 1px solid #000000;
  border-radius: 48px;
  transform: rotate(9.53deg);
}

.blogs-button-front {
  box-sizing: border-box;

  position: fixed;
  width: 204.52px;
  height: 86px;
  left: 363.33px;
  top: 328px;

  background: #76B7CC;
  border: 1px solid #000000;
  border-radius: 48px;
  transform: rotate(9.59deg);
  transition: transform 0.5s ease;
}

.blogs-button-front:active {
  transform: translateX(377.24 - 363.33px) translateY(320 - 328px) rotate(9.53deg);
}

.blogs-button-text {
  font-family: 'Roboto' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 86px;
  text-align: center;
  vertical-align: middle;
  /* identical to box height */
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  color: #FFFFFF;
}
.about-button-container {

}

.about-button-back {
  box-sizing: border-box;

  position: relative;
  width: 204.52px;
  height: 86px;
  left: 633.24px;
  top: 362px;
  
  background: #76B7CC;
  border: 1px solid #000000;
  border-radius: 48px;
  transform: rotate(9.53deg);
}

.about-button-front {
  box-sizing: border-box;

  position: relative;
  width: 204.52px;
  height: 86px;
  left: 619.33px;
  top: 370px;

  background: #76B7CC;
  border: 1px solid #000000;
  border-radius: 48px;
  transform: rotate(9.59deg);
  transition: transform 0.5s ease;
}

.about-button-front:active {
  transform: translateX(633.24 - 619.33px) translateY(362 - 370px) rotate(9.53deg);
}

.about-button-text {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 86px;
  text-align: center;
  vertical-align: middle;
  /* identical to box height */
  color: #FFFFFF;
}

.linkedin-button-container {
  
}

.linkedin-button-front {
  box-sizing: border-box;

  position: relative;
  width: 204.52px;
  height: 86px;
  left: 875.33px;
  top: 412px;
  
  background: #76B7CC;
  border: 1px solid #000000;
  border-radius: 48px;
  transform: rotate(9.59deg);
  animation: 5s ease infinite pulse;
  transition: transform 0.5s ease;
}

.blogs-button-front:hover {
  cursor: pointer;
}
.about-button-front:hover {
  cursor: pointer;
}
.linkedin-button-front:hover {
  cursor: pointer;
}

.linkedin-button-front:active {
  // left: 889.24px;
  // top: 404px;
  transform: translateX(889.24 - 875.33px) translateY(404 - 412px) rotate(9.59deg);
}

.linkedin-button-back {
  box-sizing: border-box;

  position: relative;
  width: 204.52px;
  height: 86px;
  left: 889.24px;
  top: 404px;
  
  background: #76B7CC;
  border: 1px solid #000000;
  border-radius: 48px;
  transform: rotate(9.53deg);
}

.linkedin-button-text {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */
  text-align: center;
  vertical-align: middle;
  line-height: 86px;
  color: #FFFFFF;
  
  // transform: rotate(9.38deg);
}

.carpet {
  z-index: 3;
  position: fixed;
  top: 638px;
  height: 80%;
  // width: 100em;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(189.79deg, #D9D9D9 0.65%, rgba(199, 196, 196, 0.520833) 57.66%, rgba(90, 90, 90, 0) 119.63%);  
  transform: $slope;
}

.base-board {
  position: fixed;
  top: 600px;
  height: 80%;
  // width: 100em;
  right: 0;
  left: 0;
  bottom: 0;
  transform: $slope;
  background: linear-gradient(268.61deg, #B1B1B1 1.16%, #B8B8B8 5.59%);
  z-index: 2;
}

.base-board-top {
  position: fixed;
  top: 595px;
  height: 80%;
  // width: 100em;
  right: 0;
  left: 0;
  bottom: 0;
  background: #76B7CC;
  transform: $slope;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0px -4px 15px #76b7cc;
}

.base-board-top::after {
}